@font-face {
  font-family: 'Gilroy-Black';
  src: local('Gilroy-Black'), url(~assets/fonts/Gilroy/Gilroy-Black.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: local('Gilroy-ExtraBold'), url(~assets/fonts/Gilroy/Gilroy-ExtraBold.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-ExtraBoldItalic';
  src: local('Gilroy-ExtraBoldItalic'), url(~assets/fonts/Gilroy/Gilroy-ExtraBoldItalic.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(~assets/fonts/Gilroy/Gilroy-Light.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(~assets/fonts/Gilroy/Gilroy-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-MediumItalic';
  src: local('Gilroy-MediumItalic'), url(~assets/fonts/Gilroy/Gilroy-MediumItalic.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Regular';
  src: local('Gilroy-Regular'), url(~assets/fonts/Gilroy/Gilroy-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-RegularItalic';
  src: local('Gilroy-RegularItalic'), url(~assets/fonts/Gilroy/Gilroy-RegularItalic.otf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-SemiBold';
  src: local('Gilroy-SemiBold'), url(~assets/fonts/Gilroy/Gilroy-SemiBold.otf) format('truetype');
}
