@use 'styles/media-query' as *;
@use 'styles/themes' as *;

$width-xlarge: 20rem;
$width-large: 15rem;
$width-medium: 10rem;

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: calc(var(--scale) * 0.5rem);
  margin: 0 0 calc(var(--scale) * 0.5rem) 0;

  @include themify($themes) {
    color: themed($base-text);
    box-shadow: 0 4px 4px 0 themed($box-shadow);
    background-color: themed($body-bg);
  }

  @include md {
    width: calc(var(--scale) * $width-medium);
  }

  @include lg {
    width: calc(var(--scale) * $width-large);
  }

  @include xl {
    width: calc(var(--scale) * $width-xlarge);
  }
}

.zoomContainer {
  display: flex;
  min-width: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  height: calc(100vh - 235px);
  padding: calc(var(--scale) * 1rem);
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);

  @include themify($themes) {
    color: themed($base-text);
    box-shadow: 0 4px 4px 0 themed($box-shadow);
    background-color: themed($body-bg);
  }

  @include md {
    height: 60vh;
  }

  @include xl {
    height: calc(100vh - 235px);
  }

  @include xxl {
    height: calc(100vh - 235px);
  }
}

.topBar {
  width: 100%;

  .topRow {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}

.titleFull {
  text-align: center;
  font-size: 1rem;

  @include themify($themes) {
    color: themed($base-text);
  }

  @include md {
    font-size: 2rem;
  }

  @include lg {
    font-size: 3rem;
  }

  @include xl {
    font-size: 4rem;
  }
}

.valueContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  min-height: 48px;

  .value {
    font-size: 8rem;
    font-weight: 600;
    margin-right: 0.5rem;

    @include md {
      font-size: 1rem;
    }

    @include lg {
      font-size: 2rem;
    }

    @include xl {
      font-size: 2rem;
    }

    @include xxl {
      font-size: 2rem;
    }
  }

  .unit {
    margin: 0;
    font-size: 1.8rem;

    @include xl {
      font-size: 1.8rem;
    }

    @include xxl {
      font-size: 1.8rem;
    }
  }

  .zoomValue {
    font-size: 8rem;
    font-weight: 600;
    margin-right: 0.5rem;

    @include xl {
      font-size: 8rem;
    }

    @include xxl {
      font-size: 12rem;
    }
  }
  .zoomUnit {
    margin: 0;
    font-size: 2.5rem;

    @include xl {
      font-size: 2rem;
    }

    @include xxl {
      font-size: 4rem;
    }
  }
}

.barGraphContainer {
  width: 100%;
  flex-grow: 0.5;
  display: flex;
}
