@use 'styles/media-query' as *;
@use 'styles/themes' as *;

.dialog {
  width: 100vw;
  min-height: 100vh;

  @include md {
    max-width: 60vw;
    min-height: auto;
  }
}

.search {
  display: flex;
  align-items: center;
  height: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  border: solid silver 1px;
  border-radius: 5px;
}

.search:focus-within {
  box-shadow: 0 0 0 0.25rem rgb(0 117 190 / 25%);
  border-style: solid;
  border-color: #80badf;
  border-width: 1px;
}

.searchIcon {
  justify-content: flex-end;
  margin-right: 10px;
  margin-left: 10px;
}

.searchInput {
  flex-grow: 1;
  width: 75%;
  border: transparent;
}

.searchInput:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

.searchMessage {
  margin: 10px 5px;
  @include themify($themes) {
    color: themed($base-text) !important;
  }
}
