@use 'styles/media-query' as *;
@use 'styles/themes' as *;

.ribbonContainer {
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 0.5rem;
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: wrap;

  @include themify($themes) {
    background-color: themed($body-bg);
  }

  @include lg {
    padding: 0.5rem 1rem;
    flex-direction: row;
  }
}

.ribbonGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0.2rem 0.2rem 0.2rem;

  @include md {
    margin: 0 0.1rem;
  }
}

.ribbonItem {
  display: flex;
  margin: 0 0.2rem;

  :global .k-button-text {
    @include themify($themes) {
      color: themed($primary-color);
    }
  }

  @include md {
    margin: 0 0.1rem;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.button {
  :global .k-button-text {
    @include themify($themes) {
      color: themed($primary-color);
    }
  }
}
