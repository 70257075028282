@use 'styles/colors' as *;
@use 'styles/media-query' as *;
@use 'styles/themes' as *;

.languageSelector {
  margin-top: 2rem;
  display: flex;

  @include md {
    margin-top: 1rem;
  }
}
