@use 'styles/themes' as *;

.panel {
  :global .p-panel-header,
  :global .p-panel-content {
    @include themify($themes) {
      background-color: themed($base-bg) !important;
      border-color: themed($box-shadow) !important;
      color: themed($base-text);
    }
  }

  :global .p-panel-icons button {
    @include themify($themes) {
      color: themed($base-text);
    }
  }
}
