@use 'styles/colors';
@use 'styles/variables';
@use 'styles/media-query' as *;

.navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 200;
  background-color: colors.$andritz-black-100;
  height: variables.$navbar-height;
  padding: 0;
  padding-right: 1rem;
  display: flex;

  .container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    flex-grow: 1;
    align-items: stretch;

    .leftContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @include md {
        margin-left: 5px;
      }

      .logo {
        display: flex;
        text-decoration: none;
        flex-direction: row;
        align-items: center;

        img {
          width: 4rem;
          margin-right: 0.2rem;

          @include md {
            margin-right: 1rem;
          }
        }

        h5 {
          margin: 0;
          color: colors.$andritz-blue-100;
          font-family: 'Gilroy-ExtraBold', sans-serif !important;

          &:first-of-type {
            font-family: 'Gilroy-SemiBold', sans-serif !important;
            margin-right: 5px;
            color: #fff;
          }
        }
      }
    }

    .rightContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      overflow: hidden;
      flex-grow: 1;

      > img {
        width: 560px;
        margin-right: 20px;
        height: 118%;
        object-fit: scale-down;
        display: none;

        @include lg {
          display: block;
        }
      }

      .user {
        .avatar {
          display: inline-block;
          flex-shrink: 0;
          position: relative;
          border-radius: 0.475rem;
          margin-left: 15px;
          cursor: pointer;

          img {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
          }
        }
      }

      .help {
        img {
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
        }
      }

      .notification {
        .avatar {
          display: inline-block;
          flex-shrink: 0;
          position: relative;
          border-radius: 0.475rem;
          margin-left: 15px;
          cursor: pointer;

          img {
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

.popup {
  min-width: 18rem !important;
  margin: 0.5rem 0.5rem 0 0 !important;
  padding: 0 !important;
  right: 15px !important;
  left: unset !important;

  @include md {
    min-width: 15rem !important;
  }
}

.mobilePopup {
  left: auto !important;
  word-wrap: break-word;
  word-break: break-all;
}

.notificationPopup {
  width: 15rem !important;
  margin: 1rem 5rem 0 0 !important;
  padding: 0 !important;
}
