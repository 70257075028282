@use 'styles/themes' as *;

.tabContainer {
  height: 100%;
  display: flex;
  :global div[role='tabpanel'] {
    height: 100%;
  }
  :global .k-item.k-state-active {
    span:not(.k-icon) {
      @include themify($themes) {
        color: themed($base-text);
      }
      font-family: 'Gilroy-ExtraBold';
    }
  }

  :global .k-tabstrip-items-wrapper {
    overflow-y: hidden;
    overflow-x: auto;
  }
}
