@use 'styles/colors';
@use 'styles/media-query' as *;
@use 'styles/themes' as *;

.appCard {
  margin: 15px 15px 15px 0px;
  border-radius: 5px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 150px;

  @include themify($themes) {
    background-color: themed($base-bg);
    color: themed($base-text);
  }

  @include md {
    margin: 20px 20px 20px 0px;
  }

  &:hover {
    transform: translateY(-1px);
    cursor: pointer;
  }

  .link {
    text-decoration: none;
    color: inherit;
  }

  .cardHeader {
    position: relative;
    background: url('~assets/backgroundForIcon.png') no-repeat;
    background-position: center;
    background-size: 64% 83%;
    padding: 20px 10px;

    .statusRibbon {
      width: 150px;
      padding: 10px;
      position: absolute;
      text-align: center;
      color: #f0f0f0;
      top: 10px;
      left: -50px;
      transform: rotate(-45deg);
      font-size: 13px;

      &.blue {
        background-color: colors.$andritz-blue-100;
      }
      &.orange {
        background-color: colors.$andritz-signal-orange-100;
      }
      &.green {
        background-color: colors.$andritz-signal-green-100;
      }
    }

    img {
      width: 100%;
      height: 70px;
      padding: 15px;
      object-fit: contain;
      filter: brightness(0) invert(1);
    }
  }

  .cardBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    text-align: center;

    p {
      font-size: 13px;
      text-align: center;
    }
  }

  &.blue {
    border-bottom: 5px solid colors.$andritz-blue-100;
  }

  &.orange {
    border-bottom: 5px solid colors.$andritz-signal-orange-100;
  }

  &.green {
    border-bottom: 5px solid colors.$andritz-signal-green-100;
  }
}
