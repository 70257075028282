@use 'styles/colors';
@use 'styles/media-query' as *;
@use 'styles/themes' as *;

.container {
  padding: 1rem;
  height: 100%;

  @include themify($themes) {
    box-shadow: 0 4px 8px 0 themed($box-shadow);
    color: themed($base-text);
    background-color: themed($body-bg);
  }

  @include md {
    height: calc(100% - 1rem);
    margin: 0.5rem;
  }

  .title {
    font-size: 1.5rem;
    font-family: 'Gilroy', sans-serif !important ;
    font-weight: bold;
    text-align: left;
    padding: 0px 5px;

    @include themify($themes) {
      color: themed($base-text);
    }
  }

  .search {
    display: flex;
    align-items: center;
    height: auto;
    margin-bottom: 10px;
    border: solid silver 1px;
    border-radius: 5px;
  }

  .search:focus-within {
    box-shadow: 0 0 0 0.25rem rgb(0 117 190 / 25%);
    border-style: solid;
    border-color: #80badf;
    border-width: 1px;
  }

  .searchIcon {
    justify-content: flex-end;
    margin-right: 10px;
    margin-left: 10px;
  }

  .searchInput {
    flex-grow: 1;
    width: 75%;
    border: transparent;
  }

  .searchInput:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
  }
}
