@use 'styles/themes' as *;
@use 'styles/colors' as *;

.card {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 300px;
  padding: 1rem;
  @include themify($themes) {
    background-color: themed($body-bg);
  }
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;

  &:hover {
    transform: translateY(-0.5px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
  }

  &.blue {
    &:after {
      background-color: $andritz-blue-100;
    }
  }
  &.red {
    &:after {
      background-color: #c63737;
    }
  }
  &.green {
    &:after {
      background-color: $andritz-signal-green-100;
    }
  }
  &.yellow {
    &:after {
      background-color: $andritz-signal-yellow-100;
    }
  }
  &.grey {
    &:after {
      background-color: #8a8a8a;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;

  .imageSide {
    display: flex;
    align-items: center;
    justify-content: center;

    .imageWrapper {
      position: relative;
      background: url('~assets/backgroundForIcon.png') no-repeat;
      background-position: center;
      height: 70px;
      width: 70px;
      border-radius: 50px;
      padding: 0.5rem 1rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &:hover {
        filter: brightness(0.8);
        cursor: pointer;
      }
    }
  }

  .body {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    text-align: center;

    p {
      font-size: 13px;
      text-align: center;
    }
  }
}

.hr {
  margin-bottom: 0.2rem;
  margin-top: 0.5rem;
}

.ribbon {
  display: flex;

  @include themify($themes) {
    background-color: themed($body-bg);
  }

  button {
    margin: 0;
  }
}
