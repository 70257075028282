@use 'styles/media-query' as *;

.linearContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.zoomContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  @include md {
    flex-direction: row;
  }

  .cardZoom {
    flex-grow: 1;
    height: 100%;

    > div {
      width: 100%;
    }
  }

  .cardList {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow-x: auto;
    padding-top: 1rem;

    @include md {
      min-width: calc(350px + 2rem);
      width: 30vw;
      padding: 0 1rem;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
