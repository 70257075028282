@use 'styles/themes' as *;
@use 'styles/media-query' as *;

.searchInput {
  flex-grow: 1;
  width: 75%;
  margin-right: 1rem;
}

.dialog {
  width: 100vw;
  min-height: 100vh;

  @include md {
    width: 70vw;
    min-height: 90vh;
  }
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  margin-right: 15px;

  > span {
    font-size: 24px !important;
  }
}

.bottomButton {
  margin: 15px 0 0;
}

.confirmationContent {
  display: flex;
  align-items: center;

  i {
    margin-right: 10px;
  }
}

.actionButton.dark {
  img {
    filter: brightness(0) invert(1);
  }
}

.text {
  @include themify($themes) {
    color: themed($base-text);
  }
}
