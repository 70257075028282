@use 'styles/media-query' as *;
@use 'styles/themes' as *;

$width-xlarge: 25rem;
$width-large: 20rem;
$width-medium: 15rem;

.title {
  font-size: 0.8rem;
  font-weight: bold;
}

.zoomButton {
  padding-bottom: 0.6rem !important;
}

.topRow {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;

  @include themify($themes) {
    color: themed($base-text);
    box-shadow: 0 4px 8px 0 themed($box-shadow);
    background-color: themed($body-bg);
  }

  @include md {
    width: $width-medium;
  }

  @include lg {
    width: $width-large;
  }

  @include xl {
    width: $width-xlarge;
  }
}
