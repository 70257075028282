@use 'styles/variables';
@use 'styles/themes' as *;

.main {
  min-height: 100vh;
  height: 100vh;

  &.paddingTop {
    padding-top: variables.$navbar-height;
  }

  @include themify($themes) {
    background-color: themed($body-bg);
  }

  :global div[role='tabpanel'] {
    height: 100%;
  }
}
