@use 'styles/colors';
@use 'styles/themes' as *;

.menu {
  margin: 0px;
  display: flex;
  flex-direction: column;
  border-radius: 0.475rem;
  box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
  font-weight: 500;
  font-size: 1.075rem;
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include themify($themes) {
    background-color: themed($base-bg);
    color: themed($base-text);
  }

  .item {
    display: block;
    padding: 0;
    &:first-child {
      padding-left: 0.75rem;
    }
    padding-left: 1.25rem;

    .content {
      padding: 0.65rem 1rem;
      align-items: center;
      display: flex;

      .avatar {
        display: inline-block;
        flex-shrink: 0;
        position: relative;
        border-radius: 0.475rem;
        margin-right: 1.25rem;

        img {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
        }
      }

      .column {
        display: flex;
        flex-direction: column;

        h5 {
          font-weight: 600;
          font-size: 1.15rem;
          align-items: center;
          margin: 0;
          padding: 0;
        }

        span {
          @include themify($themes) {
            color: themed($base-text);
          }
          font-weight: 500;
          font-size: 0.95rem;
          transition: color 0.2s ease, background-color 0.2s ease;

          &:hover {
            transition: color 0.2s ease, background-color 0.2s ease;
            color: colors.$andritz-blue-100;
          }
        }
      }
    }

    .link {
      @include themify($themes) {
        color: themed($base-text);
      }
      border-radius: 0.475rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      flex: 0 0 100%;
      padding: 0.65rem 1rem;
      transition: none;
      outline: 0;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
      text-decoration: none;

      &:hover {
        transition: color 0.2s ease, background-color 0.2s ease;
        color: colors.$andritz-blue-100;
        background-color: rgba(245, 248, 250, 0.8);
      }
    }

    .languageSpan {
      color: #3f4254;
      display: flex;
      align-items: center;
      flex-grow: 1;
      position: relative;
      justify-content: space-between;
      &:hover {
        transition: color 0.2s ease, background-color 0.2s ease;
        color: colors.$andritz-blue-100;
      }

      .selectedItem {
        border-radius: 0.475rem;
        font-size: 0.85rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        right: 0;
        top: 50%;

        img {
          width: 15px;
          height: 15px;
          margin-left: 0.5rem;
        }
      }
    }

    .darkMode {
      display: flex;
      align-items: center;
      margin: 0 0.5rem;
      justify-content: center;
      & > span:first-of-type {
        margin-right: 0.5rem;
      }
    }
  }
}

.separator {
  display: block;
  height: 0;
  border-bottom: 1px solid #eff2f5;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
