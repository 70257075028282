.loader {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
