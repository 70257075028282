// ANDRITZ BLUE
$andritz-blue-100: #0075be;
$andritz-blue-90: #1983c4;
$andritz-blue-80: #3391cb;
$andritz-blue-70: #4c9ed1;
$andritz-blue-60: #66acd8;
$andritz-blue-50: #7fbade;
$andritz-blue-40: #99c8e5;
$andritz-blue-30: #b2d5eb;
$andritz-blue-20: #cce3f2;
$andritz-blue-10: #e5f1f8;

// ANDRITZ DARK BLUE
$andritz-dark-blue-100: #003a70;
$andritz-dark-blue-90: #194d7e;
$andritz-dark-blue-80: #33618d;
$andritz-dark-blue-70: #4c759b;
$andritz-dark-blue-60: #6689a9;
$andritz-dark-blue-50: #7f9cb7;
$andritz-dark-blue-40: #99b0c6;
$andritz-dark-blue-30: #b2c4d4;
$andritz-dark-blue-20: #ccd8e2;
$andritz-dark-blue-10: #e5ebf0;

// ANDRITZ BLACK
$andritz-black-100: #000;
$andritz-black-90: #191919;
$andritz-black-80: #333333;
$andritz-black-70: #4c4c4c;
$andritz-black-60: #666666;
$andritz-black-40: #999999;
$andritz-black-30: #b2b2b2;
$andritz-black-20: #cccccc;
$andritz-black-10: #e5e5e5;

// ANDRITZ SIGNAL ORANGE
$andritz-signal-orange-100: #f07e3b;
$andritz-signal-orange-90: #f18b4e;
$andritz-signal-orange-80: #f39862;
$andritz-signal-orange-70: #f4a475;
$andritz-signal-orange-60: #f6b289;
$andritz-signal-orange-50: #f7be9d;
$andritz-signal-orange-40: #f9cbb1;
$andritz-signal-orange-30: #fad8c4;
$andritz-signal-orange-20: #fce5d8;
$andritz-signal-orange-10: #fdf2eb;

// ANDRITZ SIGNAL GREEN
$andritz-signal-green-100: #47a23f;
$andritz-signal-green-90: #59ab52;
$andritz-signal-green-80: #6cb565;
$andritz-signal-green-70: #7ebe78;
$andritz-signal-green-60: #91c78c;
$andritz-signal-green-50: #a3d09f;
$andritz-signal-green-40: #b5dab2;
$andritz-signal-green-30: #c7e3c5;
$andritz-signal-green-20: #daecd9;
$andritz-signal-green-10: #ecf6eb;

// ANDRITZ SIGNAL YELLOW
$andritz-signal-yellow-100: #fece43;
$andritz-signal-yellow-90: #fed355;
$andritz-signal-yellow-80: #fed869;
$andritz-signal-yellow-70: #fedd7b;
$andritz-signal-yellow-60: #fee28e;
$andritz-signal-yellow-50: #fee6a1;
$andritz-signal-yellow-40: #ffebb4;
$andritz-signal-yellow-30: #fff0c6;
$andritz-signal-yellow-20: #fff5d9;
$andritz-signal-yellow-10: #fffaec;
