@use 'styles/themes' as *;
@import './fonts';

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;

  &:not(.k-icon):not(.pi) {
    font-family: 'Gilroy-Regular', sans-serif;
    b {
      font-family: 'Gilroy-SemiBold', sans-serif;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'Gilroy-ExtraBold', sans-serif;
    }
  }
}

html,
body,
#root {
  min-height: 100%;
  @include themify($themes) {
    background-color: themed($body-bg);
  }
}

:root {
  color-scheme: var(--color-scheme);
}

body {
  -webkit-font-smoothing: antialiased !important;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.uppercase {
  text-transform: uppercase;
}

.not-allowed {
  cursor: not-allowed;
}

.k-icon-24 {
  font-size: 24px; /* Sets icon size to 24px */
}

.k-icon-32 {
  font-size: 32px; /* Sets icon size to 32px */
}

.k-icon-48 {
  font-size: 48px; /* Sets icon size to 48px */
}

.k-icon-64 {
  font-size: 64px; /* Sets icon size to 64px */
}

.red {
  color: #ff0000;
}

.k-animation-container,
.p-multiselect-panel {
  z-index: 2147483647 !important;
}
