// small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// small tablets (portrait view)
$screen-md-min: 768px;
// tablets and small desktops
$screen-lg-min: 992px;
// large tablets and desktops
$screen-xl-min: 1200px;
// extra large desktops
$screen-xxl-min: 1441px;

// Mixins
// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
// Very large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

// Very large desktops
@mixin xxl {
  @media (min-width: #{$screen-xxl-min}) {
    @content;
  }
}

/* HOW TO USE 
  in the following example:
  
  .divName {
    padding: 10px; // will be applied on screens shorter than md (768px)
    @include md {
      padding: 20px; // will be applied only in screens larger than md (768px)
    }
  }

  it means that on devices larger than 'md' (768px) padding will be 20px
  on other devices (< 768) padding will be 10px;
*/
