@use 'styles/themes' as *;

.titleContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  .mainTitle {
    font-size: 1.2rem;
    font-family: 'Gilroy-SemiBold';

    @include themify($themes) {
      color: themed($base-text);
    }
  }

  .title {
    font-size: 0.9rem;
    font-family: 'Gilroy-Regular';

    @include themify($themes) {
      color: themed($base-text);
    }
  }
}
