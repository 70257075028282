@use 'styles/themes' as *;
@use 'styles/colors' as *;

.tabView {
  :global .p-tabview-nav,
  :global .p-tabview-nav-link {
    @include themify($themes) {
      background-color: themed($base-bg) !important;
      color: themed($subtle-text) !important;
    }
  }

  :global .p-unselectable-text.p-tabview-selected.p-highlight .p-tabview-nav-link {
    color: $andritz-blue-100 !important;
  }

  :global .p-tabview-panels {
    @include themify($themes) {
      background-color: themed($base-bg) !important;
      color: themed($base-text) !important;
    }
  }
}
