@use 'styles/media-query' as *;
@use 'styles/themes' as *;
@use 'styles/colors' as *;

.form {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include md {
    margin-top: 1rem;
  }

  .header {
    margin-bottom: 2rem;
    font-size: 1.5rem;
  
    @include md {
      margin: 1rem 1rem 2rem 1rem;
      font-size: 1.5rem;
    }
  }

  .field {
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    text-align: left;

    span {
      width: 100%;
    }

    &.checkBox {
      padding-top: 1rem;
    }

    :global .k-label,
    :global .k-checkbox-label {
      text-transform: capitalize;
    }

    .error {
      color: red;
      font-weight: bold;
      text-align: left;
    }

    .passInputHidden {
      position: relative;

      Input {
        width: 100%;
      }

      i {
        position: absolute;
        right: 5px;
        cursor: pointer;
        font-size: 30px;
        transform: translateY(100%);
      }
    }

    .passInputHidden i:hover {
      color: $andritz-blue-100;
    }

    .passInputHidden i:active {
      color: #98fb98;
    }

    .passInputShown {
      position: relative;

      Input {
        width: 100%;
      }

      i {
        position: absolute;
        right: 5px;
        cursor: pointer;
        font-size: 30px;
        color: silver;
        transform: translateY(100%);
      }
    }

    .passInputShown i:hover {
      color: $andritz-blue-100;
    }

    .passInputShown i:active {
      color: red;
    }
  }

  .footerButtons {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;

    a {
      margin-top: 1rem;
    }
  }
}
