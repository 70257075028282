$primary-color: 'primary-color';
$base-text: 'base-text';
$body-bg: 'body-bg';
$subtle-text: 'subtle-text';
$disabled-text: 'disabled-text';
$base-bg: 'base-bg';
$hovered-text: 'hovered-text';
$hovered-bg: 'hovered-bg';
$box-shadow: 'box-shadow';
$input-border-color: 'input-border-color';
$field-bg: 'field-bg';

$themes: (
  light: (
    primary-color: #0075be,
    base-text: #212529,
    body-bg: #ffffff,
    subtle-text: #6c757d,
    disabled-text: #8f8f8f,
    base-bg: #f8f9fa,
    hovered-text: #16181b,
    hovered-bg: #e9ecef,
    box-shadow: rgba(0, 0, 0, 0.2),
    input-border-color: #ced4da,
    field-bg: #dddddd
  ),
  dark: (
    primary-color: #ffffff,
    base-text: #ffffff,
    body-bg: #16181b,
    subtle-text: #cccccc,
    disabled-text: #aaaaaa,
    base-bg: #212529,
    hovered-text: #ffffff,
    hovered-bg: #343a40,
    box-shadow: rgba(0, 0, 0, 0.605),
    input-border-color: #6c757d,
    field-bg: #b1b1b1
  ),
);

@function themed($key) {
  @return map-get($theme-map, $key);
}

@mixin themify($themes: $themes) {
  @each $theme, $map in $themes {
    :global(.theme--#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}
