@use 'styles/colors' as *;

.badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.blue {
    background-color: #b3e5fc;
    color: $andritz-blue-100;
  }
  &.red {
    background-color: #ffcdd2;
    color: #c63737;
  }
  &.green {
    background-color: $andritz-signal-green-100;
    color: rgba(255, 255, 255, 0.993);
  }
  &.yellow {
    background-color: $andritz-signal-yellow-100;
    color: #8a5340;
  }
  &.grey {
    background-color: #e0e0e0;
    color: #8a8a8a;
  }
}
