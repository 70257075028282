@use 'styles/media-query' as *;
@use 'styles/themes' as *;
@use 'styles/colors';

.loginPage {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .password {
    margin-top: 1rem;

    @include md {
      margin-top: 1.5 rem;
    }
  }

  .bottomPage {
    background: url('~assets/images/metris_mesh.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
  }

  .metrisLogo {
    position: absolute;
    width: 150px;
    height: auto;
    right: 2.5rem;
    top: 2.5rem;
  }

  .background {
    background-color: white;
    @include themify($themes) {
      background-color: themed($body-bg);
    }

    justify-content: center;
    display: flex;
    position: absolute;
    transform: translate(50%, -50%);
    top: 50%;
    right: 50%;
    margin: auto;
    text-align: center;
    padding: 2rem;

    @include md {
      height: 62vh;
      max-height: 500px;
      width: 60vw;
    }

    @include lg {
      height: 62vh;
      width: 50vw;
      max-width: 600px;
      min-height: 500px;
    }
  }

  .leftText {
    position: absolute;
    display: none;
    color: white;
    bottom: 2rem;
    left: 2rem;
    text-decoration: underline;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;

    @include md {
      display: block;
    }
  }

  .rightText {
    position: absolute;
    display: inline-flex;
    color: white;
    bottom: 2rem;
    right: 2rem;
    align-items: flex-end;

    span {
      margin-left: 0.5rem;
    }

    img {
      width: 2rem;
    }
  }
}

.link {
  font-family: Gilroy-Semibold !important;
  border-radius: 0.475rem;
  cursor: pointer;
  transition: none;
  outline: 0;
  text-decoration: none;

  &:hover {
    transition: color 0.2s ease, background-color 0.2s ease;
    color: colors.$andritz-blue-100;
    background-color: rgba(224, 236, 245, 0.5);
    font-family: Gilroy-Extrabold !important;
  }
}
