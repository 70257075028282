@use 'styles/themes' as *;

.dialog {
  :global .p-dialog-header,
  :global .p-dialog-footer,
  :global .p-dialog-content {
    @include themify($themes) {
      background-color: themed($base-bg) !important;
    }
  }

  :global .p-dialog-title {
    @include themify($themes) {
      color: themed($base-text) !important;
    }
  }

  :global .p-dialog-header-icon {
    @include themify($themes) {
      color: themed($base-text) !important;
    }
  }
}
