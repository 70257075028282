@use 'styles/colors' as *;
@use 'styles/colors';
@use 'styles/media-query' as *;
@use 'styles/themes' as *;

.page {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .header {
    margin-bottom: 2rem;
    @include md {
      margin: 1rem 1rem 2rem 1rem;
    }
  }

  .bottomPage {
    background: url('~assets/images/metris_mesh.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
  }

  .metrisLogo {
    position: absolute;
    width: 150px;
    height: auto;
    right: 2.5rem;
    top: 2.5rem;
  }

  .background {
    background-color: white;
    @include themify($themes) {
      background-color: themed($body-bg);
    }

    justify-content: center;
    display: flex;
    width: 40vw;
    min-width: 300px;
    height: 450px;
    position: absolute;
    transform: translate(50%, -50%);
    top: 50%;
    right: 50%;
    margin: auto;
    text-align: center;

    @include md {
      height: 62vh;
      max-height: 500px;
      width: 60vw;
    }

    @include lg {
      height: 62vh;
      width: 50vw;
      max-width: 600px;
      min-height: 500px;
    }
  }

  .leftText {
    position: absolute;
    display: none;
    color: white;
    bottom: 2rem;
    left: 2rem;
    text-decoration: underline;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;

    @include md {
      display: block;
    }
  }

  .rightText {
    position: absolute;
    display: inline-flex;
    color: white;
    bottom: 2rem;
    right: 2rem;
    align-items: flex-end;

    span {
      margin-left: 0.5rem;
    }

    img {
      width: 2rem;
    }
  }
}

.error {
  color: red;
  font-weight: bold;
  text-align: left;
}

.form {
  margin-top: 2rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include md {
    margin-top: 2rem;
    padding: 2rem;
  }

  .languageSelector {
    margin-bottom: 3rem;
    @include md {
      margin-top: 1rem;
      margin-bottom: 3rem;
    }
  }

  .header {
    margin: 1rem 1rem 0.5rem 1rem;
    @include md {
      margin: 1rem 1rem 1rem 1rem;
    }
  }

  .codeButtons {
    flex-direction: row;
    width: 40%;
  }

  .backButton {
    font-family: Gilroy-Semibold !important;
    border-radius: 0.475rem;
    cursor: pointer;
    transition: none;
    outline: 0;
    text-decoration: none;
    margin-top: 1rem;
    color: $andritz-black-60;

    &:hover {
      transition: color 0.2s ease, background-color 0.2s ease;
      color: $andritz-black-80;
      font-family: Gilroy-Extrabold !important;
    }
  }

  .field {
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    text-align: left;

    span {
      width: 100%;
    }

    &.checkBox {
      padding-top: 1rem;
    }

    :global .k-label,
    :global .k-checkbox-label {
      text-transform: capitalize;
    }

    .error {
      color: red;
      font-weight: bold;
      text-align: left;
    }

    .passInputHidden {
      position: relative;

      Input {
        width: 100%;
      }

      i {
        position: absolute;
        right: 5px;
        cursor: pointer;
        font-size: 30px;
        transform: translateY(100%);
      }
    }

    .passInputHidden i:hover {
      color: $andritz-blue-100;
    }

    .passInputHidden i:active {
      color: #98fb98;
    }

    .passInputShown {
      position: relative;

      Input {
        width: 100%;
      }

      i {
        position: absolute;
        right: 5px;
        cursor: pointer;
        font-size: 30px;
        color: silver;
        transform: translateY(100%);
      }
    }

    .passInputShown i:hover {
      color: $andritz-blue-100;
    }

    .passInputShown i:active {
      color: red;
    }
  }

  .footerButtons {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;

    a {
      margin-top: 1rem;
    }
  }
}
