@use 'styles/media-query' as *;

.container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.linearContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.zoomContainer {
  width: 100%;
  display: flex;
  height: 88% !important;
  flex-direction: column;

  @include md {
    flex-direction: row;
  }

  @include xxl {
    height: 92% !important;
  }

  .cardZoom {
    flex-grow: 1;
    height: auto;

    > div {
      width: 100%;
    }
  }

  .cardList {
    height: auto;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow-x: auto;
    padding-top: 1rem;

    @include md {
      min-width: calc(350px + 2rem);
      padding: 0 1rem;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
    }

    > div > span {
      width: 100% !important;
    }
  }
}

.zoomContainerTablet {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;

  .cardZoomTablet {
    flex-grow: 1;
    height: auto;
  }

  .cardListTablet {
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow-x: auto;
    padding-top: 1rem;
  }
}
