@use 'styles/colors' as *;
@use 'styles/media-query' as *;
@use 'styles/themes' as *;

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include md {
    padding: 0.5rem;
  }

  .backButton {
    font-family: Gilroy-Semibold !important;
    border-radius: 0.475rem;
    cursor: pointer;
    transition: none;
    outline: 0;
    text-decoration: none;
    margin-top: 1rem;
    color: $andritz-black-60;
    display: flex;
    justify-content: center;

    &:hover {
      transition: color 0.2s ease, background-color 0.2s ease;
      color: $andritz-black-80;
      font-family: Gilroy-Extrabold !important;
    }
  }

  .field {
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    text-align: left;

    span {
      width: 100%;
    }

    &.checkBox {
      padding-top: 1rem;
    }

    :global .k-label,
    :global .k-checkbox-label {
      text-transform: capitalize;
    }

    .error {
      color: red;
      font-weight: bold;
      text-align: left;
    }

    .passInputHidden {
      position: relative;

      Input {
        width: 100%;
      }

      i {
        position: absolute;
        right: 5px;
        cursor: pointer;
        font-size: 30px;
        transform: translateY(100%);
      }
    }

    .passInputHidden i:hover {
      color: $andritz-blue-100;
    }

    .passInputHidden i:active {
      color: #98fb98;
    }

    .passInputShown {
      position: relative;

      Input {
        width: 100%;
      }

      i {
        position: absolute;
        right: 5px;
        cursor: pointer;
        font-size: 30px;
        color: silver;
        transform: translateY(100%);
      }
    }

    .passInputShown i:hover {
      color: $andritz-blue-100;
    }

    .passInputShown i:active {
      color: red;
    }
  }

  .footerButtons {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;

    a {
      margin-top: 1rem;
    }
  }
}
