@use 'styles/media-query' as *;
@use 'styles/themes' as *;
@use 'styles/colors';

.dialog-content {
  display: flex;
  justify-content: center;
}

.password-input {
  width: 40px;
  height: 40px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 24px;
  letter-spacing: 8px;
  text-align: center;
  margin-right: 5px;
  box-sizing: border-box;
}

.password-input:focus {
  outline: none;
}

.password-input::-webkit-outer-spin-button,
.password-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.password-input[type='number'] {
  -moz-appearance: textfield;
}

.centralize {
  justify-content: center;
  text-align: center;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  margin-left: 20px;
}

.my-dialog {
  font-weight: bold;
  text-align: center;
  span {
    font-size: 18px;
    text-align: center;
  }
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  span {
    width: 100%;
    text-align: center;
  }
}
