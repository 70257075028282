@use 'styles/themes' as *;

.dropdown {
  :global .p-dropdown-label,
  :global .p-dropdown-header,
  :global .p-dropdown-item {
    @include themify($themes) {
      color: themed($base-text) !important;
    }
  }

  @include themify($themes) {
    background-color: themed($body-bg) !important;
    border-color: themed($input-border-color) !important;
    color: themed($base-text);
  }
}

:global .p-dropdown-panel {
  z-index: 2147483647 !important;
}

:global .p-dropdown-item,
:global .p-dropdown-items,
:global .p-dropdown-header,
:global .p-inputtext,
:global .p-dropdown-filter-icon,
:global .p-dropdown-trigger,
:global .p-dropdown-empty-message,
:global .p-dropdown-label-empty,
:global .p-dropdown-label,
:global .p-inputwrapper {
  @include themify($themes) {
    background-color: themed($body-bg) !important;
    border-color: themed($input-border-color) !important;
    color: themed($base-text) !important;
  }
}
